module.exports = {
  messages: {
    PROFILE_ERROR_SOMETHING_WENT_WRONG:
      'Something went wrong. Please try again later.',
    PROFILE_LO_FE_ALPHABETIZED_DIRECTORY_HEADING:
      'Browse over 21 million XING members',
    PROFILE_LO_FE_ALPHABETIZED_DIRECTORY_SUB_HEADING: 'Last name:',
    PROFILE_LO_FE_BENEFITS_BUTTON_CTA: 'Register now',
    PROFILE_LO_FE_BENEFITS_COMMUNITY_TEXT:
      'Find just the right job for you on XING.',
    PROFILE_LO_FE_BENEFITS_COMMUNITY_TITLE: 'Over 1 million jobs',
    PROFILE_LO_FE_BENEFITS_FREE_TEXT:
      'Connect with new people and gather inspiration to boost your working life.',
    PROFILE_LO_FE_BENEFITS_FREE_TITLE: '22 million members',
    PROFILE_LO_FE_BENEFITS_HEADLINE: 'XING – The jobs network',
    PROFILE_LO_FE_BENEFITS_NETWORK_TEXT:
      'Get found by employers and over 20,000 recruiters.',
    PROFILE_LO_FE_BENEFITS_NETWORK_TITLE: 'Job offers just for you',
    PROFILE_LO_FE_LANGUAGE_SKILLS_HEADLINE: 'Languages',
    PROFILE_LO_FE_LANGUAGE_SKILLS_LEVEL_1: 'Basic',
    PROFILE_LO_FE_LANGUAGE_SKILLS_LEVEL_2: 'Intermediate',
    PROFILE_LO_FE_LANGUAGE_SKILLS_LEVEL_3: 'Fluent',
    PROFILE_LO_FE_LANGUAGE_SKILLS_LEVEL_4: 'First language',
    PROFILE_LO_FE_LEGAL_NOTICE_HEADLINE: 'Legal notice',
    PROFILE_LO_FE_LEGAL_NOTICE_SUB_HEADLINE: 'Legal notice for {displayName}',
    PROFILE_LO_FE_MEMBERSHIP_TEXT:
      'Basic membership is free, but the opportunities are priceless.',
    PROFILE_LO_FE_MEMBERSHIP_TITLE: 'Free membership',
    PROFILE_LO_FE_SIGNUP_LAYER_HEADLINE:
      'Want to find out more about {firstName}?',
    PROFILE_LO_FE_SIGNUP_LAYER_LOGIN_CTA: 'Log in now',
    PROFILE_LO_FE_SIGNUP_LAYER_SUB_HEADLINE:
      "Log in or register with XING – it's free of charge and only takes 2 minutes!",
    PROFILE_LO_FE_SIMILAR_PROFILES_HEADLINE:
      'XING members with similar profiles',
    PROFILE_LO_FE_SKILLS_HEADLINE: 'Skills',
    PROFILE_LO_FE_STICKY_LAYER_HEADLINE:
      'Want to find out more about {displayName}?',
    PROFILE_LO_FE_STICKY_LAYER_SUB_HEADLINE:
      "Log in or register with XING – it's free of charge and only takes 2 minutes!",
    PROFILE_LO_FE_TIMELINE_CURRENT: 'Current',
    PROFILE_LO_FE_TIMELINE_EDUCATION_HEADLINE:
      'Educational background for {firstName} {lastName}',
    PROFILE_LO_FE_TIMELINE_HEADLINE: 'Timeline',
    PROFILE_LO_FE_TIMELINE_WORK_EXPERIENCE_HEADLINE:
      'Professional experience for {firstName} {lastName}',
    PROFILE_LO_FE_UNFENCED_CTA: 'Log in now to view their full profile.',
    PROFILE_LO_FE_USER_BAG_HEADLINE_FEMALE:
      'Looking for a different {firstName} {lastName}?',
    PROFILE_LO_FE_USER_BAG_HEADLINE_MALE:
      'Looking for a different {firstName} {lastName}?',
    PROFILE_LO_FE_USER_BAG_HEADLINE_NONE:
      'Looking for a different {firstName} {lastName}?',
    PROFILE_LO_FE_USER_BAG_SHOW_MORE_CTA: 'More matches',
    PROFILE_LO_FE_XING_ID_CTA: 'Log in now to view all entries.',
    PROFILE_LO_PREVIEW_ALREADY_LOGGED_IN_V2:
      "This is what people see when logged in. If they're logged out, they'll have the option to either log in or register.\n(Not shown in this profile preview)",
    PROFILE_LO_PREVIEW_CANT_REPORT: "You can't report previewing profiles.",
    PROFILE_LO_PREVIEW_CLOSE_PREVIEW: 'Close preview',
    PROFILE_LO_PREVIEW_INFO_BANNER:
      "This is how your profile appears to others when they're not logged in to XING.",
    PROFILE_LO_REPORT_PROFILE_LINK: 'Report profile',
    PROFILE_LO_XING_ID_MAIN_CTA_BUTTON: 'See whole profile',
    PROFILE_LO_XING_ID_MAIN_CTA_BUTTON_RECURRING_USER: 'Log in',
    PROFILE_LO_XINGID_MORE: 'More',
    USER_FLAG_ERROR_PARAGRAPH_1: "This information isn't available right now.",
    USER_FLAG_ERROR_PARAGRAPH_2: 'Please try again later.',
    USER_FLAG_LOADING: 'Loading...',
  },
};
